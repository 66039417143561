@font-face {
    font-family: BentonSansRegular;
    src: url("bentonsans/BentonSans Regular.otf") format("opentype");
}
@font-face {
    font-family: BentonSansBold;
    src: url("bentonsans/BentonSans Bold.otf") format("opentype");
}


html {
    height: 100%;
}

body {
    color: #000;
    margin: 1rem;
    height: calc(100% - 2rem);
    font-family: BentonSansRegular;
}
body.dark {
    color: #fff;
    background-color: #252525;
}
button {
    font-family: BentonSansBold;
}
#root {
    height: 100%;
}

@media screen and (min-width: 480px) {
    body {
        margin: 1rem auto;
        max-width: calc(480px - 2rem);
    }
}

